.profile-name-section {
    font-size: xx-large;
}
.profile-name-section :hover {
    border: 5px;
    border-style: solid;
    border-radius: 10px;
    border-color: red
}
/* @page {
    size:  A4;
    margin: 27mm 16mm 27mm 16mm;
} */