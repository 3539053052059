.grid-items {
    display: flex;
    flex-flow: row wrap;
    /* background-color: DodgerBlue; */
    flex-direction: row;
}

.grid-items>div {
    /* background-color: #f1f1f1; */
    width: 100px;
    margin: 10px;
    /* text-align: center; */
    line-height: 75px;
    /* font-size: 30px; */
}

.grid-item {
    flex: 1 calc(50% - 20px);
    /* changed */
    flex: 1;
    padding: 0 50px 40px;
    box-sizing: border-box;
    /* min-height: 650px; */
    min-width:35%;/* 650px;*/
    /* max-width: 700px; */
    position: relative;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    display: flex;
    /* text-align: center; */
}

.grid-item-wide {
    flex: 1 100%;
    /* min-height: 575px; */
    padding-bottom: 20px;
}