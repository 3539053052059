.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
}

.app-header {
  display: flex;
  max-height: 60px;
  flex: 1;
}

.app-content {
  display: flex;
  flex: 1;
}

.app-content-navbar {
  display: flex;
  flex: 1;
  max-width: 280px;
}

.app-content-page {
  display: flex;
  flex: 1;

  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
}
